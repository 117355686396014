import React, {useEffect} from "react"

import TabPanel from '../TabPanel';
import DashboardCard from '../DashboardCard';
import SectionGrid from '../../../layout/SectionGrid';
import * as Users from "../../../actions/Users";
import {connect, useDispatch, useSelector} from 'react-redux';
import {isStandardUser, isUserTask, isAssigned} from "../../../utils/Helpers";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import Grid from "@mui/material/Grid";
import {toggleMainPageTab} from "../../../actions/Layout/Menu";
import OverviewCard from "../OverviewTab/OverviewCard";
import PrimaryHeader from "../../PrimaryHeader";
import PaymentPanel from "../OverviewTab/PaymentPanel";
import Button from "@mui/material/Button";

/**
 * @param papdis
 * @returns {boolean}
 */
const shouldActivateUploadCTA = (papdis): boolean => {
	if (!papdis) {
		return false
	}
	const lastUploadDate = papdis[0]?.updated_at;

	return lastUploadDate
		? (moment().diff(moment(lastUploadDate), 'days') > 10)
		: false;
}

const UploadWithoutApi: React.Component = ({
   buttons = [],
   assigned = false,
   toggleTab,
}: {

	buttons: [any],
	card_id: number | null,
	assigned: string,
}): JSX.Element => (
	<Grid
		className={"collegia-border collegia-container-wrapper"}
		container
		alignItems={"center"}
	>
		<Grid
			xs={6}
			md={6}
			lg={6}
			item
		>
			<Grid
				container
				alignItems={"center"}
			>
				<h3
					className={"title"}
					style={{marginRight: 20, color: "#3C69E4"}}
				>
					Upload your pension file for the next month
				</h3>
			</Grid>
		</Grid>

		{buttons.length > 0 && (
			<Grid
				xs={6}
				md={6}
				lg={6}
				item
				container
				justifyContent={"flex-end"}
			>
				<Grid
					xs={6}
					sm={4}
					md={4}
					lg={4}
					item
					style={{textAlign: 'center'}}
				>
					<Button
						fullWidth
						disabled={buttons[0].loading}
						className={buttons[0].active ? "MuiCardHeader-button" : ""}
						onClick={() => toggleTab(2)}
					>
						DO IT NOW
					</Button>
				</Grid>
			</Grid>
		)}
	</Grid>
);

/**
 * @param index
 * @param tab_index
 * @param toggleTab
 * @returns {JSX.Element}
 * @constructor
 */
const OverviewTab: React.Component = (
	{
		index,
		tab_index = 0,
		toggleTab = () => {
		},
	}: {
		index: number,
		tab_index: number,
	}
): JSX.Element => {
	const
		// Important!: Talk to gian before delete this comment - assign button
		//
		// {
		// 	list: userlist = [],
		// 	cardsLoading = []
		// } = useSelector(state => (state?.Users || {})),
		// loading_users = useSelector(state => state?.Users?.isLoading),
		papdis = useSelector(state => state?.Papdis),
		{
			profile_data = {
				software_doc: {
					id: null,
					guide: {
						is_third_party_software: false,
					},
				}
			}
		} = useSelector(state => state?.Profile);

	const {
		employer_id,
		cards = [],
		// Important!: Talk to gian before delete this comment - assign button
		// id = 0,
		employer = {
			software_doc: {
				id: null,
				guide: {
					is_third_party_software: false,
				},
			},
		},
	}: {
		last_contribution: number | null,
		next_contribution: number | null,
		employer_id: number,
		cards: [],
		id: number,
		employer: {
			emandate: null,
			is_push_payment: false,
		},
		software_doc: {
			id: null,
			guide: {
				is_third_party_software: false,
			},
		}
	} = profile_data;

	let {
		setup_complete = false,
		emandate = false,
		is_employer_under_bureau = false,
		is_push_payment = false,
	} = employer;

	if (!employer?.emandate) {
		setup_complete = false;
	}

	const apiIntegrated = profile_data?.software_doc?.guide?.is_third_party_software ?? false;
	const displayUploadCTA = shouldActivateUploadCTA(papdis) || (!apiIntegrated);
	const displayPensionCards = apiIntegrated;
	const uploadManually = (!apiIntegrated);

	// TODO: Add task identifier to first argument of showAssignmentModal
	const dispatch = useDispatch();
	const push = useNavigate();

	useEffect(() => {
		if (employer_id) {
			dispatch(Users.getUsersList(employer_id));
		}
	}, [
		employer_id,
		dispatch
	]);
	sessionStorage.setItem("employer_id", profile_data.employer_id);
	const user_is_standard = isStandardUser(profile_data);
	// Important!: Talk to gian before delete this comment - assign button
	// const showAssignmentModal = task => dispatch(Users.showAssignmentModal(task, userlist, id));
	// const cancelAssignment = (task) => dispatch(Users.showCancelAssignmentModal(task));

	const makeTaskButtons = task => {
		return !user_is_standard && task.task_id !== 1
			? [
				{
					text: "DO IT NOW",
					active: true,
					onClick: () => {
						task.task_id === 2 ? dispatch(toggleMainPageTab(2)) : push(task.task_url)
					}
				},
				// Important!: Talk to gian before delete this comment - assign button
				// (isAssigned(task)
				// 	? {
				// 		text: "Cancel Assignment",
				// 		active: false,
				// 		loading: cardsLoading.includes(task.id),
				// 		onClick: () => cancelAssignment(task),
				// 	}
				// 	:
				// 	{
				// 		text: "Assign",
				// 		active: false,
				// 		loading: loading_users || cardsLoading.includes(task.id),
				// 		onClick: () => showAssignmentModal(task),
				// 	})
			]
			: [
				{
					text: "DO IT NOW",
					active: true,
					onClick: () => {
						task.task_id === 2 ? dispatch(toggleMainPageTab(2)) : push(task?.task_url)
					}
				},
			]
	};

	const shouldDisplayCard = {
		"1": (setup_complete === false || emandate === false) && !is_employer_under_bureau,
		"2": setup_complete && displayUploadCTA && profile_data.software_doc,
		"3": setup_complete && !profile_data.software_doc,
	};

	if (is_push_payment) {
		if (profile_data.software_doc && displayUploadCTA) {
			shouldDisplayCard["2"] = true;
		}
		if (!profile_data.software_doc?.id) {
			shouldDisplayCard["3"] = true;
		}
	}

	return (
		<TabPanel className={"tab-panel"} value={tab_index} index={index}>
			<PrimaryHeader>
				Home
			</PrimaryHeader>

			<OverviewCard profile_data={profile_data}/>

			<SectionGrid>
				{cards.map((task: {
					name: String,
					description: String,
					color: String,
					icon: string,
					id: number,
					taskname: string,
				}, index) =>
					(shouldDisplayCard[task?.task_id] || isUserTask(task, profile_data))
					&& (
						<Grid
							className={"collegia-border"}
							container
							alignItems={"center"}
							key={index}
						>
							<DashboardCard
								key={`task-${index}`}
								header={task.name}
								subheader={task.description}
								color={task.color}
								// Important!: Talk to gian before delete this comment - assign button
								// assigned={isAssigned(task)}
								// buttons={(user_is_standard && !isUserTask(task, profile_data))
								// 	? [] : makeTaskButtons(task)}
								buttons={makeTaskButtons(task)}
							/>
						</Grid>
					))
				}

				{displayPensionCards && (
					<PaymentPanel
						contributions={[]}
						outstanding_amount={profile_data?.outstanding_amount?.total?.toString() ?? ''}
					/>
				)}

				{cards.map((task: {
					name: String,
					description: String,
					color: String,
					icon: string,
					id: number,
					taskname: string,
				}, index) =>
					(shouldDisplayCard[2] !== null && uploadManually && cards.length === 1) && setup_complete
					&& (
						<UploadWithoutApi
							key={`task-${index}`}
							assigned={isAssigned(task)}
							buttons={makeTaskButtons(task)}
							toggleTab={toggleTab}
						/>
					))

				}
			</SectionGrid>
		</TabPanel>
	);
}

export default connect(
	state => ({
		...(state.Menu),
		...(state.Profile),
	}), dispatch => ({
		toggleTab: (selectedTab: number = 0) => dispatch(toggleMainPageTab(selectedTab ?? 0)),
	}))(OverviewTab);