import {retrieveCollegiaAccessToken} from "../../utils/Helpers";
import * as types from "./types";
import {
	DOWNLOAD_EMPLOYEE_REPORT,
	FETCH_STAGING_DATE_API,
	GENERATE_COMPANY_NUMBER_FETCH,
	GET_COMMUNICATIONS,
	THIRD_PARTY_SOFTWARE_FLOW_RESET,
	THIRD_PARTY_SOFTWARE_NOTIFY_NOT_INTEGRATED,
} from "./types";
import EmployerService from "../../services/Employer";
import * as SnackBar from "../SnackBar";
import {warnAPIStatus} from "../SnackBar";
import {requestNewLogin} from "../Profile";
import {handleRequestError} from "../../utils/Actions";
import FileDownload from "js-file-download";
import * as XLSX from "xlsx";
import Request from "../../utils/Request";

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const updateStagingDate = (data: any[] = false) => dispatch => {
	if (retrieveCollegiaAccessToken()) {
		dispatch({
			type: FETCH_STAGING_DATE_API,
			payer_loading: true,
		});

		new EmployerService()
			.updateStagingDate(data)
			.then(response => {
				dispatch({
					type: FETCH_STAGING_DATE_API,
					payer_loading: false,
				});
				window.location.href = "/main-page";

			})
			.catch(error => {
				console.error(error);

				dispatch({
					type: FETCH_STAGING_DATE_API,
					payer_loading: false,
				});
			});
	}
};

/**
 * @returns {function(*): Promise<void | void>}
 */
export const getPayrollSoftwareList = () => dispatch => {
	dispatch({type: types.THIRD_PARTY_SOFTWARE_LIST_STARTED});

	new EmployerService()
		.getPayrollSoftwareList()
		.then(({data}) => {
			switch (data.code) {
				case 200:
					dispatch({type: types.THIRD_PARTY_SOFTWARE_LIST_SUCCESS, options: data?.data});
					break;
				default:
					dispatch(warnAPIStatus(data?.code, types.THIRD_PARTY_SOFTWARE_LIST_FAILURE));
					return
			}
		})
		.catch(data => {
			dispatch({type: types.THIRD_PARTY_SOFTWARE_LIST_FAILURE});
			console.error(data);
		});
}

/**
 * @param token
 * @param payroll_software_guide_id
 * @param callback
 * @returns {(function(*): (*|undefined))|*}
 */
export const updatePayrollSoftware = (
	token: string = "",
	payroll_software_guide_id: number = 0,
	callback = () => {
	}
) => dispatch => {
	dispatch({type: types.THIRD_PARTY_SOFTWARE_SELECT_STARTED});

	// TODO: Should this notify the API?
	if (payroll_software_guide_id === 0) return dispatch({type: THIRD_PARTY_SOFTWARE_NOTIFY_NOT_INTEGRATED})


	new EmployerService()
		.updateThirdPartySoftware(payroll_software_guide_id)
		.then(({data}) => {
			switch (data.code) {
				case 200:
				case 201:
					const message = data.data.message
					dispatch({type: types.THIRD_PARTY_SOFTWARE_SELECT_SUCCESS})
					dispatch(SnackBar.info(message))
					callback()
					break;
				default:
					dispatch(warnAPIStatus(data.code, types.THIRD_PARTY_SOFTWARE_SELECT_FAILURE))
					break;
			}
		})
		.catch(data => {
			dispatch({type: types.THIRD_PARTY_SOFTWARE_SELECT_FAILURE});
			console.error(data);
		});
}

/**
 * @returns {(function(*): void)|*}
 */
export const disconnectService = () => dispatch => {
	dispatch({type: types.THIRD_PARTY_SOFTWARE_DISCONNECT_STARTED});

	new EmployerService()
		.disconnectService()
		.then(({data}) => {
			switch (data.code) {
				case 200:
				case 201:
					const message = data.data.message
					dispatch({type: types.THIRD_PARTY_SOFTWARE_DISCONNECT_SUCCESS})
					dispatch(SnackBar.info(message))
					break;
				default:
					dispatch(warnAPIStatus(data.code, types.THIRD_PARTY_SOFTWARE_SELECT_FAILURE))
					break;
			}
		})
		.catch(data => {
			dispatch({type: types.THIRD_PARTY_SOFTWARE_DISCONNECT_FAILURE});
			console.error(data);
		});
}

/**
 * @param page_index
 * @returns {(function(*): void)|*}
 */
export const getEmployeesList = (page_index = 1) => dispatch => {
	dispatch({type: types.EMPLOYEES_LIST_STARTED});

	new EmployerService()
		.getEmployeesList(page_index)
		.then(({data}) => {
			const employees = data?.data?.employees
			const employees_opt_out = data?.data?.employees_opted_out
			switch (data.code) {
				case 200:
					dispatch({type: types.EMPLOYEES_LIST_SUCCESS, employees, employees_opt_out});
					break;
				default:
					dispatch(warnAPIStatus(data.code, types.EMPLOYEES_LIST_FAILURE))
					break;
			}
		})
		.catch(data => {
			if (data !== undefined) {
				if (data?.response) {
					if (data?.response?.status === 401) {
						window.localStorage.clear();
						window.sessionStorage.clear();
						window.location = "/login";
						requestNewLogin(false);
					}
				}
			}
			dispatch({type: types.EMPLOYEES_LIST_FAILURE});
			console.error('error: ', data);
		});
};

/**
 * @returns {{type: string}}
 */
export const resetPayrollSoftwareForm = () => ({
	type: THIRD_PARTY_SOFTWARE_FLOW_RESET
});

/**
 * @param userData
 * @returns {(function(*): void)|*}
 */
export const disconnectEmployee = (userData: any) => dispatch => {
	dispatch({type: types.DISCONNECT_EMPLOYEE_STARTED});

	new EmployerService()
		.disconnectEmployee(userData)
		.then(({data, status}) => {
			switch (status) {
				case 200:
					dispatch(getEmployeesList(1))
					break;
				default:
					dispatch(warnAPIStatus(data.code, types.DISCONNECT_EMPLOYEE_FAILURE))
					break;
			}
		})
		.catch(data => dispatch(handleRequestError(types.DISCONNECT_EMPLOYEE_FAILURE, data)));
};

/**
 *
 * @param data
 * @returns {(function(*): void)|*}
 */
export const updateContributionDate = (data) => dispatch => {
	if (retrieveCollegiaAccessToken()) {
		dispatch({
			type: FETCH_STAGING_DATE_API,
			payer_loading: true,
			update_contribution_date: false,
		});

		new EmployerService()
			.updateContributionDate(data)
			.then(() => {
				dispatch({
					type: FETCH_STAGING_DATE_API,
					payer_loading: false,
					update_contribution_date: true,
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: FETCH_STAGING_DATE_API,
					payer_loading: false,
					update_contribution_date: false,
				});
			});
	}
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const getStagingDateByApiAction = (data: any[] = false) => dispatch => {

	if (retrieveCollegiaAccessToken()) {
		dispatch({
			type: FETCH_STAGING_DATE_API,
			payer_loading: true,
		});

		new EmployerService()
			.getStagingDateByApiService(data)
			.then(response => {
				let auxDate = response?.data?.message?.stagingDate.split("-");
				dispatch({
					type: FETCH_STAGING_DATE_API,
					staging_date: auxDate[2] + "/" + auxDate[1] + "/" + auxDate[0],
					payer_loading: false,
				});
			})
			.catch(() => {
				dispatch({
					type: FETCH_STAGING_DATE_API,
					staging_date: "",
					payer_loading: false,
				});
				dispatch(SnackBar.error("Unable to find staging date for this tax office number!"));
			});
	}
};

/**
 * @returns {(function(*): void)|*}
 */
export const generateCompanyNumber = (clearGenerateCompanyNumber: boolean = false) => dispatch => {
	if (clearGenerateCompanyNumber) {
		dispatch({
			type: GENERATE_COMPANY_NUMBER_FETCH,
			generate_company_number: ""
		});
	} else {
		new EmployerService()
			.generateCompanyNumber()
			.then(response =>
				dispatch({
					type: GENERATE_COMPANY_NUMBER_FETCH,
					generate_company_number: response?.data?.data,
				})
			)
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @param setDisableButton
 */
export const confirmOptOutContribution = (data: any[] = false, setDisableButton) => dispatch => {
	if (data) {
		setDisableButton(true);
		new EmployerService()
			.confirmOptOutContribution(data)
			.then(() => {
				setDisableButton(false);
				window.location.reload();
			})
			.catch(error => {
				setDisableButton(false);
				console.error(error);
			});
	}
};

export const downloadEmployeesReport = () => dispatch => {
	dispatch({
		type: DOWNLOAD_EMPLOYEE_REPORT,
		employees_contributions_loading: true,
		profile_loading: true,
	});

	new EmployerService()
		.downloadEmployeeReport()
		.then(response => FileDownload(
			response.data,
			`Employee report.csv`
		))
		.catch(error => {
			console.error(error);
		})
		.finally(() => dispatch({
			type: DOWNLOAD_EMPLOYEE_REPORT,
			employees_contributions_loading: false,
			profile_loading: false,
		}));
};

export const downloadEmployeeAllContributionOrPerPeriod = (data = {}, employeeName = "") => dispatch => {
	dispatch({
		type: DOWNLOAD_EMPLOYEE_REPORT,
		employees_contributions_loading: true,
		profile_loading: true,
	});

	new EmployerService()
		.downloadEmployeeAllContributionOrPerPeriod(data)
		.then(response => {
			const worksheet = XLSX.utils.json_to_sheet(response.data.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
			const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "csv" });

			FileDownload(
				excelFileContent,
				employeeName + '.csv'
			);

			}
		)
		.catch(error => {
			console.error(error);
		})
		.finally(() => dispatch({
			type: DOWNLOAD_EMPLOYEE_REPORT,
			employees_contributions_loading: false,
			profile_loading: false,
		}));
};

export const getEmployeeCommunications = (data) => dispatch => {
	dispatch({
		type: GET_COMMUNICATIONS,
		is_loading: true,
	});

	Request.post(`/employers/get-employee-communication`, data)
		.then(response => dispatch({
			type: GET_COMMUNICATIONS,
			is_loading: false,
			employees_communications: response.data.data,
		}))
		.catch(error => {
			dispatch({
				type: GET_COMMUNICATIONS,
				is_loading: false,
			});
			console.error(error);
		});
};