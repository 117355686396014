import Wrapper from "../layout/Logged/Wrapper";
import {useNavigate} from "react-router-dom";
import {getDebitInformation, me} from "../actions/Profile";
import {connect} from "react-redux";
import React from "react";
import Loading from "../components/Loading";
import Grid from "@mui/material/Grid";
import SectionGrid from "../layout/SectionGrid";
import AccountPrimaryHeader from "../components/MyAccount/AccountPrimaryHeader";
import Typography from "@mui/material/Typography";
/**
 * @param profile_data
 * @returns {JSX.Element}
 * @constructor
 */
const DebitInformation = (
	{
		debit_information = [],
		debit_information_loading = false,
	}
): JSX.Element => {
	const history = useNavigate();

	let fields = [
		{
			title: "Ddi status",
			subtitle: debit_information?.ddi_status ? "Active" : "Inactive",
			color: debit_information?.ddi_status ? "green" : "red"
		},
		{
			title: "Account Number",
			subtitle: debit_information?.account_number,
			color: "black"
		},
		{
			title: "Bank Name",
			subtitle: debit_information?.bank_name,
			color: "black"
		},
		{
			title: "Sort Code",
			subtitle: debit_information?.sort_code,
			color: "black"
		},
	];

	if (debit_information_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={history}
		>
			<SectionGrid id={"my-account-section-grid"}>
				<Grid
					id={"menu-logged-wrapper"}
					justifyContent={"center"}
					container
				>
					<AccountPrimaryHeader
						title={"Direct Debit Details"}
						history={history}
						location={"/my-account"}
					/>
					<Grid
						className={"collegia-border"}
						container
						padding={"40px"}
						justifyContent={"center"}
					>
						{
							fields.map(({subtitle, title, color}, index) =>
								<Grid
									key={`field-${index}`}
									item xs={6}
									marginBottom={"2%"}
								>
									<Typography
										noWrap
										className={"company-info-typog"}
									>
										<b>{title}: </b>
										<Typography
											component={"span"}
											color={color}
										>
											{subtitle}
										</Typography>
									</Typography>
								</Grid>
							)
						}
					</Grid>
				</Grid>
			</SectionGrid>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	return {
		...state.Profile,
	};
};

const mapDispatchToProps = () => dispatch => ({
	me: dispatch(me()),
	getDebitInformation: dispatch(getDebitInformation())
});

export default connect(mapStateToProps, mapDispatchToProps)(DebitInformation);