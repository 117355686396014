import React from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import logo from "../../images/logo-icon.svg";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = (props = {
	className: "",
	includeLogo: false,
}): JSX.Element => (
	<Grid
		id={"footer-logged"}
		xs={12}
		lg={12}
		md={12}
		direction={"column"}
		alignItems={"center"}
		alignContent={"center"}
		item
		container
		className={props?.className || ""}
	>
		{props?.includeLogo ?
			<Grid
				xs={12}
				md={12}
				sm={12}
				lg={12}
				item
			>
				<img
					src={logo}
					alt={"Collegia logo."}
					className={"default-collegia-logo"}
				/>
			</Grid> : null
		}
		<Grid
			xs={12}
			md={12}
			sm={12}
			lg={12}
			item
		>
			<Link href={"https://www.collegia.co.uk"} target={"_blank"}>
				<span>{new Date().getFullYear()} ©</span> Collegia
			</Link>
		</Grid>
	</Grid>
);

export default Footer;