import {resolveReduxState} from "../../utils/Helpers";
import {
	ACCOUNT_ACTIVATION_PROCEED,
	ADD_NEW_EXTRA_CONTRIBUTIONS, CANCEL_PREPARE_EMANDATE,
	FETCH_EXTRA_CONTRIBUTIONS,
	FETCH_ORDER_ID_DETAILS, PREPARE_EMANDATE, RETRIEVE_BANK_LIST, VERIFY_EMANDATE
} from "../../actions/DirectDebit/types";

const initial_state = {
	new_payment_object: false,
	contributions: false,
	contribution: false,
	bank_list: [],
	payment_loading: false,
	endToEnd: "",
	activation_step: 0,
	confirm_mandate_loading: false,
	cancel_mandate_loading: false,
	token: "",
	prepare_emandate_loading: false,
	verify_emandate_loading: false,
};

/**
 * @store
 * @param state
 * @param action
 * @returns {{contributions: boolean, contribution: boolean, new_payment_object: boolean}|{valueOf, (): boolean}|boolean}
 */
const DirectDebitReducerStore = (state = initial_state, action = false ) => {

	if (action) {
		const {type = false} = action;

		if (type) {
			if (type === ADD_NEW_EXTRA_CONTRIBUTIONS) return resolveReduxState(state, action);
			else if (type === FETCH_EXTRA_CONTRIBUTIONS) return resolveReduxState(state, action);
			else if (type === FETCH_ORDER_ID_DETAILS) return resolveReduxState(state, action);
			else if (type === RETRIEVE_BANK_LIST) return resolveReduxState(state, action);
			else if (type === ACCOUNT_ACTIVATION_PROCEED) return { ...state, activation_step: state.activation_step + 1};
			else if (type === PREPARE_EMANDATE) return resolveReduxState(state, action);
			else if (type === CANCEL_PREPARE_EMANDATE) return resolveReduxState(state, action);
			else if (type === VERIFY_EMANDATE) return resolveReduxState(state, action);
		}
	}


	return state;
};

export default DirectDebitReducerStore;