import SelectBanks from "./SelectBanks";
import ConfirmMonthlyContributions from './ConfirmMonthlyContributions';
import Success from './Success';

const DirectDebitPages = {
	SelectBanks,
	ConfirmMonthlyContributions,
	Success
};

export default DirectDebitPages;