import Create from "./Create";
import RetrievePaymentInfo from "./RetrievePaymentInfo";
import Active from "./Active";
import Details from "./Details";
import BankList from "./BankList";
import ConfirmDirectDebit from "./ConfirmDirectDebit";
import GetEndToEnd from "./GetEndToEnd";
import CancelDirectDebitSigning from "./CancelDirectDebitSigning";
import PrepareEmandate from "./PrepareEmandate";
import CancelPrepareEmandate from "./CancelPreparateEmandate";
import VerifyIfTheEmandateFinish from "./VerifyIfTheEmandateFinish";

/**
 *
 * @type {{Active: (function(): Promise<AxiosResponse<*>>), Details: (function(string=): Promise<AxiosResponse<*>>), GetEndToEnd: (function(): Promise<AxiosResponse<*>>), RetrievePaymentInfo: (function(string=, string=): Promise<AxiosResponse<*>>), Create: (function(*=): Promise<AxiosResponse<*>>), ConfirmDirectDebit: (function(*=): Promise<AxiosResponse<*>>), BankList: (function(): Promise<AxiosResponse<*>>), CancelDirectDebitSigning: (function(string=): Promise<AxiosResponse<*>>)}}
 */
const DirectDebitRequests = {
	Create,
	RetrievePaymentInfo,
	Active,
	Details,
	BankList,
	ConfirmDirectDebit,
	GetEndToEnd,
	CancelDirectDebitSigning,
	PrepareEmandate,
	CancelPrepareEmandate,
	VerifyIfTheEmandateFinish,
};

/**
 *
 */
export default DirectDebitRequests;