import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from '../../request';
import {AxiosResponse} from "axios";
// import {prepareEmandate, verifyIfTheMandateFinish} from "../../actions/DirectDebit";
// import CancelPrepareEmandate from "../../request/DirectDebit/CancelPreparateEmandate";
// import VerifyIfTheEmandateFinish from "../../request/DirectDebit/VerifyIfTheEmandateFinish";

export default class DirectDebitService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.DirectDebit);
	}

	/**
	 * @param orderId
	 * @param type
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>|*}
	 */
	retrievePaymentInfo(orderId: string = "", type:string = ""): Promise<never>|Promise<AxiosResponse<*>>|* {
		if (orderId !== "" && type !== "" && this.request) {
			return this?.request?.RetrievePaymentInfo(orderId ?? "", type ?? "");
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param orderId
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	details(orderId: string = "") {
		if (orderId !== "" && this.request) {
			return this?.request?.Details(orderId ?? "");
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @returns {Promise<AxiosResponse<*>>|undefined|Promise<AxiosResponse<*>>|*}
	 */
	bankList(): Promise<AxiosResponse<*>>|null {
		if (this?.request) {
			return this?.request?.BankList();
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @returns {Promise<never>|undefined|Promise<AxiosResponse<*>>|*}
	 */
	confirmDirectDebit(data:any = false): null|Promise<AxiosResponse<*>>|* {
		if (this?.request && data) {
			return this?.request?.ConfirmDirectDebit(data ?? false);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @returns {Promise<never>|undefined|Promise<AxiosResponse<*>>|*}
	 */
	getEndToEnd(): null|Promise<AxiosResponse<*>>|* {
		if (this?.request) {
			return this?.request?.GetEndToEnd();
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param endToEnd
	 * @returns {Promise<never>|undefined|Promise<AxiosResponse<*>>|*}
	 */
	cancelDirectDebitSigning(endToEnd:string = ""): Promise<never>|Promise<AxiosResponse<*>>|* {
		if (this?.request && endToEnd) {
			return this?.request?.CancelDirectDebitSigning(endToEnd ?? "");
		}

		return Promise?.reject("No end to end verification and request handle provided.");
	}

	/**
	 * @returns {Promise<never>|undefined|Promise<AxiosResponse<*>>|*}
	 * @param data
	 */
	prepareEmandate(data: any = []): Promise<never>|Promise<AxiosResponse<*>>|* {
		if (this?.request && data) {
			return this?.request?.PrepareEmandate(data);
		}

		return Promise?.reject("No end to end verification and request handle provided.");
	}

	/**
	 * @returns {Promise<never>|undefined|Promise<AxiosResponse<*>>|*}
	 * @param id
	 */
	cancelPrepareEmandate(id: number = 0): Promise<never>|Promise<AxiosResponse<*>>|* {
		if (this?.request && id) {
			return this?.request?.CancelPrepareEmandate(id ?? "");
		}

		return Promise?.reject("No end to end verification and request handle provided.");
	}

	/**
	 * @returns {Promise<never>|undefined|Promise<AxiosResponse<*>>|*}
	 */
	verifyIfTheMandateFinish(id: number = 0): Promise<never>|Promise<AxiosResponse<*>>|* {
		if (this?.request) {
			return this?.request?.VerifyIfTheEmandateFinish(id);
		}

		return Promise?.reject("No end to end verification and request handle provided.");
	}
}