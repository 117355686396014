// importing the default redux store creators.
import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from 'redux-thunk';

// importing the application store.
import Menu from './Layout/Menu';
import Profile from './Profile';
import SignUp from "./SignUp";
import SnackBar from "./SnackBar";
import BillingTypesStore from "./BillingTypes";
import Employer from "./Employer";
import PAPDIS from "./PAPDIS";
import Authorization from "./Authorization";
import Notifications from "./Notifications";
import Popup from "./Popup";
import Users from "./Users";
import DirectDebit from "./DirectDebit";
import Contributions from './Contributions'
import MyAdvisorsRequest from "./MyAdvisorsRequest";
import AdvisorRequestAlert from "./AdvisorRequestAlert";
import Materials from "./Materials";
import Benefits from "./Benefits";
import Referral from "./Referral";
import Invitations from "./Invitations";
import MyIfas from "./MyIfas";
import Forbidden from './Forbbiden';

const reducers = combineReducers({
	Menu,
	Profile,
	SignUp,
	SnackBar,
	BillingTypesStore,
	Employer,
	PAPDIS,
	Authorization,
	Notifications,
	Popup,
	Users,
	DirectDebit,
	Contributions,
	MyAdvisorsRequest,
	AdvisorRequestAlert,
	Materials,
	Benefits,
	Referral,
	Invitations,
	MyIfas,
	Forbidden,
});

/**
 * @return {store}
 */
export default createStore(
	reducers,
	applyMiddleware(thunk),
);
